<template>
  <div>
    <v-data-table :headers="headers" :items="files" class="elevation-1">
      <template v-slot:top>
        <v-alert dense v-model="alert" type="success">
          Changes saved successfully.
        </v-alert>
        <v-toolbar flat>
          <v-toolbar-title>Files</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Create File
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.keyword"
                        label="Keyword"
                        :error-messages="fileErrors.keyword"
                        error-count="3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        ref="file"
                        :error-messages="fileErrors.file"
                        @change="selectFile"
                        truncate-length="15"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <ConfirmDelete
            v-if="deleteItem"
            :item="deleteItem"
            :delete-action="deleteAction"
            @close="deleteItem = null"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.link="{ item }">
        <span>{{ item.link }}</span
        >&nbsp;
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.link"
              v-bind="attrs"
              v-on="on"
              @click="copyToClipboard(item)"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>{{
            copiedItem.id === item.id ? "copied to clipboard" : "copy"
          }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <template v-if="fileIsUploadedManual(item)">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem = item"> mdi-delete</v-icon>
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_FILES,
  DELETE_FILE,
  CREATE_FILE,
  RESET_FILE_ERRORS,
  UPDATE_FILE,
} from "@/store/files.module";

import ConfirmDelete from "@/components/modals/ConfirmDelete";

export default {
  name: "List",
  components: { ConfirmDelete },
  mounted() {
    this.fetchFiles();
  },
  data: () => ({
    alert: false,
    dialog: false,
    deleteAction: DELETE_FILE,
    deleteItem: null,
    copiedItem: {},
    headers: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Group",
        value: "group",
      },
      {
        text: "Keyword",
        value: "keyword",
      },
      {
        text: "Link",
        value: "link",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    item: {
      id: null,
      keyword: null,
      file: null,
    },
    defaultItem: {
      id: null,
      keyword: null,
      file: null,
    },
  }),
  computed: {
    ...mapGetters(["files", "fileErrors"]),
    formTitle() {
      return this.item.id === null ? "Create File" : "Edit File";
    },
  },
  watch: {
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    fetchFiles() {
      this.$store.dispatch(GET_FILES);
    },

    selectFile(file) {
      this.item.file = file;
    },

    editItem(item) {
      this.item = Object.assign({}, item);
      this.dialog = true;
    },

    save() {
      let action = CREATE_FILE;

      if (this.item.id !== null) {
        action = UPDATE_FILE;
      }

      let data = new FormData();

      for (let key in this.item) {
        let value = this.item[key];

        if (value !== null) {
          data.append(key, value);
        }
      }

      this.$store
        .dispatch(action, data)
        .then(this.close)
        .then(this.displayAlert);
    },

    fileIsUploadedManual(item) {
      return "Manual_Upload_Template" === item.group;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
        this.$refs.file.reset();
      });
      this.$store.dispatch(RESET_FILE_ERRORS);
    },

    displayAlert() {
      this.alert = true;
    },

    copyToClipboard(item) {
      let vm = this;
      vm.copiedItem = item;
      navigator.clipboard.writeText(item.shortener.url);

      setTimeout(function () {
        vm.copiedItem = {};
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
