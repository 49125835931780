var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.files},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-alert',{attrs:{"dense":"","type":"success"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" Changes saved successfully. ")]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Files")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Create File ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Keyword","error-messages":_vm.fileErrors.keyword,"error-count":"3"},model:{value:(_vm.item.keyword),callback:function ($$v) {_vm.$set(_vm.item, "keyword", $$v)},expression:"item.keyword"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{ref:"file",attrs:{"error-messages":_vm.fileErrors.file,"truncate-length":"15"},on:{"change":_vm.selectFile}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),(_vm.deleteItem)?_c('ConfirmDelete',{attrs:{"item":_vm.deleteItem,"delete-action":_vm.deleteAction},on:{"close":function($event){_vm.deleteItem = null}}}):_vm._e()],1)]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.link))]),_vm._v("  "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.link)?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.copyToClipboard(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copiedItem.id === item.id ? "copied to clipboard" : "copy"))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.fileIsUploadedManual(item))?[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.deleteItem = item}}},[_vm._v(" mdi-delete")])]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }